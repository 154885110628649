.light-highlight {
  background-color: rgb(
    255,
    255,
    126
  ); /* Change this to your preferred highlight color */
  color: black;
  font-weight: bold;
  padding: 2px 4px;
}

.highlight {
  background-color: yellow; /* Change this to your preferred highlight color */
  color: black;
  font-weight: bold;
  padding: 2px 4px;
}
