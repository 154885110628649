*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

body {
  background-color: #f3f3f3;
  margin: 0;
}

/* Custom scrollbar for WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 10px;
}

/* Styling for the thumb in Firefox */
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* Optional: Hover effect */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
